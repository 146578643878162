@font-face {
  font-family: "Poppins Bold";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("./assets/fonts/Poppins-Bold.woff2") format("truetype");
}

@font-face {
  font-family: "Poppins Medium";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("./assets/fonts/Poppins-Medium.woff2") format("truetype");
}

@font-face {
  font-family: "BubblegumSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("./assets/fonts/BubblegumSans-Regular.woff2") format("truetype");
}

body {
  background-color: #5e1914;
}

.card {
  flex-direction: row;
  background-color: #fee8da;
  color: white;
}

.header {
  background-color: #5e1914;
}

.menu-list {
  background-color: #f4f4f5;
  padding: 20px;
}

.sec-color {
  color: white;
}

.foodName {
  color: black;
}

.offer-slider {
  background-color: #fee8da;
}

.offer-text {
  margin-right: 50px;
}

.menu-bg {
  margin: 20px;
  padding: 30px;
  background-color: #5e1914;
  border-radius: 20px;
}

.menu-list-heading {
  font-family: "BubblegumSans-Regular";
  font-size: 40px;
  color: white;
  text-align: center;
}

.menu-list-subheading {
  font-family: "BubblegumSans-Regular";
  font-size: 30px;
  color: #ffff00;
  text-align: center;
}

/* Search Box css */

/* search input field container */
.search-box {
  position: relative;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 16px;
}

.cross-icon {
  position: absolute;
  right: 10px;
  top: 16px;
  cursor: pointer;
}

/* controls the all type search input fields */
input[type="search"] {
  display: inline-block;
  background-color: #fee8da;
  color: #5e1914;
  width: 100%;
  font-size: 18px;
  padding: 10px 40px;
  outline: none;
  border: none;
  border-radius: 10px;
  font-family: "Poppins Medium";
}

/* controls the search cancel button */
[type="search"]::-webkit-search-cancel-button {
  font-size: 2rem;
}
